<template>
  <div>
  <a-card class="add-order" title="新建订单">
    <div>
      <a-row>
        <a-col class="input-line" :span="24">
          <span class="input-line-title">取货方式：</span>
            <a-radio-group v-model="formState.order_type" @change="onChangeOrderType">
            <a-radio :value="2">配送</a-radio>
            <a-radio :value="1">自提</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      
      <template v-if="formState.order_type === 2">
        <a-row class="mt-4">
          <a-col class="input-line flex items-center" :span="24">
            <span class="input-line-title"> <i class="require-i">*</i>收货地址： </span>
            <a-select class="flex-1" placeholder="请输入所在地址"
                show-search
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                v-model="formState.consignee_address"
                @search="handleSearchAddress"
                @change="handleChangeAddress">
              <a-select-option v-for="d in addressList" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
            </a-select>
            <a-input class="ml-2 flex-1" placeholder="请录入单元/楼层/房号"
                v-model="formState.detail_address"></a-input>
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col class="input-line flex items-center" :span="24">
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"> <i class="require-i">*</i>收货人： </span>
              <a-input class="flex-1" placeholder="收货人称呼"
                  v-model="formState.consignee_name" />
            </div>
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"> <i class="require-i">*</i>手机号： </span>
              <a-input class="flex-1" placeholder="收货人手机号"
                  v-model="formState.consignee_phone" />
            </div>
            <div class="flex items-center" style="flex:3;">
              <span class="input-line-title"> <i class="require-i">*</i>收货时间： </span>
              <time-picker2 class="flex-1" :value.sync="formState.delivery_time" />
            </div>
          </a-col>
        </a-row>
      </template>
      <template v-if="formState.order_type === 1">
        <a-row class="mt-4">
          <a-col class="input-line flex items-center" :span="24">
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"> <i class="require-i">*</i>取货人： </span>
              <a-input class="flex-1" placeholder="取货人称呼"
                  v-model="formState.recipient_name" />
            </div>
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"> <i class="require-i">*</i>手机号： </span>
              <a-input class="flex-1" placeholder="取货人手机号"
                  v-model="formState.recipient_phone" />
            </div>
            <div class="flex items-center" style="flex:3;">
              <span class="input-line-title"> <i class="require-i">*</i>取货时间： </span>
              <time-picker2 class="flex-1" :value.sync="formState.pickup_time" />
            </div>
          </a-col>
        </a-row>
      </template>
      <a-row class="mt-4">
        <a-col class="input-line flex items-center" :span="24">
          <div class="flex items-center" style="flex:2;">
            <span class="input-line-title"><i class="require-i">*</i>下单人：</span>
            <a-input class="flex-1" placeholder="下单人称呼"
                v-model="formState.place_order_name" />
          </div>
          <div class="flex items-center" style="flex:2;">
            <span class="input-line-title"><i class="require-i">*</i>手机号：</span>
            <a-input class="flex-1" placeholder="下单人手机号"
                v-model="formState.place_order_phone" />
          </div>
          <div class="flex items-center" style="flex:3;">
            <a-button class="ml-4" type="primary" size="small" 
                v-if="formState.order_type === 2" 
                @click="handlerCopyUser">同收货人</a-button>
            <a-button class="ml-4" type="primary" size="small" 
                v-if="formState.order_type === 1" 
                @click="handlerCopyUser">同取货人</a-button>
          </div>
        </a-col>
      </a-row>

      <a-row class="mt-4">
        <a-col class="input-line" :span="24">
          <span class="input-line-title">下单备注：</span>
          <a-input class="flex-1" placeholder="会打印在小票上、顾客可见"
              v-model="formState.remarks" />
        </a-col>
      </a-row>
      <a-row class="mt-4">
        <a-col class="input-line" :span="24">
          <span class="input-line-title">内部备注：</span>
          <a-input class="flex-1" placeholder="顾客不可见"
              v-model="formState.private_remarks" />
        </a-col>
      </a-row>

      <a-row class="mt-3">
          <a-col v-if="formState.order_type == 1" class="input-line">
            <span class="input-line-title"> <i class="require-i">*</i>自提门店： </span>
            <span class="mr-3">{{ formState.outlets_name || "待选择" }}</span>
            <a-button type="primary" size="small" 
              @click="handlerShowShopList">{{ formState.outlets_name ? "更换" : "选择" }}</a-button>
          </a-col>
          <a-col v-if="formState.order_type == 2" class="input-line">
            <span class="input-line-title"> <i class="require-i">*</i>配送网点： </span>
            <span class="mr-3">{{ formState.delivery_outlets_name || "无" }}</span>
            <div class="ml-4" style="color:#aaa;" 
                v-if="formState.delivery_outlets_id">
              （
              <span class="input-line-title">供货门店：</span>
              <span v-if="formState.outlets_name" class="mr-3">{{ formState.outlets_name }}</span>
              <a-button type="link" size="small" class="p-0" 
                @click="handlerShowShopList">{{ formState.outlets_id ? "更换" : "选择" }}</a-button>
              ）
            </div>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col class="input-line" :span="8">
          <span class="input-line-title"> <i class="require-i">*</i>购买商品： </span>
          <a-button @click="handlerAddGoods" type="primary" size="small">添加</a-button>
        </a-col>
      </a-row>
    </div>

    <base-table
      class="mt-3"
      :customHeight="400"
      ref="orderAddRef"
      :columnsData="add_columns"
      rowKey="sku_id"
      :tableData="goodsList"
    >
      <template #number="{ record }">
          <a-input-number v-model="record.number" @change="changeGoodNumber" :min="1"></a-input-number>
      </template>
      <template #operation="{ index }">
        <a-button type="link" @click="handlerDeleteSku(index)">删除</a-button>
      </template>
    </base-table>

    <!-- 蛋糕配件 -->
    <div class="mt-6 ml-3" v-if="cakeList.length">
      <span class="text-sm font-bold text-black">蛋糕配件</span>

      <div v-for="(item,i) in cakeList" :key="i*100000+item.sku_id">
        <div class="flex mt-2">
          <a-form-model layout="inline" ref="searchRef" class="flex" style="width:100%;">
            <a-form-model-item>{{ item.goods_name}}({{item.flavor_name ? item.flavor_name+'|': '' }}{{item.specification_name}})</a-form-model-item>
            <a-form-model-item prop="birthday_candle" label="蜡烛">
              <birthday-candle :value.sync="item.birthday_candle"></birthday-candle>
            </a-form-model-item>
            <a-form-model-item prop="diners_number" label="餐具">
                <a-select placeholder="请选择"
                    :default-value="0" style="width: 90px" 
                    v-model="item.diners_number">
                  <a-select-option v-for="(item, index) in tableWare"
                      :key="index" :value="item">{{ item }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="生日牌">
              <a-auto-complete v-model="item.birthday_card" :data-source="cardList"
                         style="width: 200px"
                         placeholder="生日牌"/>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>

    <div class="mt-5 ml-3">
      <div>
        商品{{ formState.goods_amount }}元 + 运费{{ formState.freight_amount }}元 =
        <span class="text-lg font-bold">{{ formState.total }}</span> 元
      </div>

    </div>
    <div class="mt-5 flex justify-center items-center">
      <a-button @click="handlerCancel" class="mr-8">取消</a-button>
      <a-button class="ml-4" type="primary" @click="handlerCreate">创建</a-button>
      <a-checkbox v-model="ignoreTime" class="ml-3">不校验时间</a-checkbox>
    </div>

  </a-card>

  <select-shop-modal
      v-if="isSelectShop"
      :formData="formState"
      :cityAdcode="shopCityAdcode"
      :show.sync="isSelectShop"
      @ok="handleShopChanged"
  ></select-shop-modal>
  <add-goods-modal
    v-if="isSelectGoods"
    :show.sync="isSelectGoods"
        :outletsId="formState.outlets_id"
    :selectRows="goodsList"
    @ok="addGoodsOk"
  ></add-goods-modal>

</div>
</template>

<script>

import { add_columns, candleOptions } from './columns'
import SelectShopModal from './components/select-shop-modal.vue'
import AddGoodsModal from './components/add-goods-modal.vue'

import { getOrderPrice, createOrder } from '@/api/outlets'
import { getPickUpOutletsList } from '@/api/order/create'
import { getMapPointList, getAdcode, getDistance } from "@/utils/tMap.js"
import { tableWare, cardList } from "@/utils/type.js"
import { checkAdcodes } from "@/utils/address.js"
import { searchShopDeliveryArea } from "./delivery.js"
import { validatePhone } from '@/hooks/use-form-rules.js'
import BirthdayCandle from '@/components/order/birthday-candle.vue'
import TimePicker2 from '@/components/order/time-picker2.vue'

export default {
  components: { 
    AddGoodsModal, 
    TimePicker2, 
    BirthdayCandle,
    SelectShopModal
  },
  data() {
    return {
      tableWare,
      cardList,
      candleOptions: candleOptions,
      add_columns,
      orderId: '',
      addressList: [],
      goodsList: [],
      cakeList: [],
      shopCityAdcode: 0,
      formState: {
        order_type: 2, //配送方式
        outlets_name: '',
        goods_amount: 0,
        freight_amount: 0,
        outlets_id: '',
        total: 0,
        delivery_outlets_id: 0,
        delivery_outlets_name: "",
        delivery_outlets_type: "",
        delivery_region_id: 0,
        consignee_lng: "",
        consignee_lat: "",
        consignee_name: "",
        consignee_phone: "",
        // delivery_time_start: "",
        // delivery_time_end: "",
        consignee_address: undefined,
        consignee_adcode: "",
        recipient_name: "",
        recipient_phone: "",
        // pick_up_goods_time_start: "",
        // pick_up_goods_time_end: "",
        place_order_name: "",
        place_order_phone: "",
      },
      
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      isSelectOutlets: false,
      isSelectGoods: false,
      isSelectShop: false,

      ignoreTime: false,
      loading: false
    }
  },
  
  mounted() {
    let outletsInfo = localStorage.getItem('outletsInfo')
    if(outletsInfo){
      try{
        outletsInfo = JSON.parse(outletsInfo)
        this.shopCityAdcode = outletsInfo && outletsInfo.outlets_city_adcode || 0
      }catch(err){
        console.log(err)
      }
    }
  },
  methods: {

    // 创建订单提交
    handlerCreate() {
      if(this.loading) return
      if (!this.validateForm()) return
      
      const params = {
        ...this.formState,
        ignore_time: this.ignoreTime ? 2 : "",
        consignee_address: this.formState.consignee_address + (this.formState.detail_address || ""),
      }

      // 自提：置空运费
      if (this.formState.order_type == 1) {
        params.freight_amount = 0
      }

      // 自提/配送时间
      if (this.formState.order_type == 2) {
        params.delivery_time_start = this.formState.delivery_time[0] + ' ' + this.formState.delivery_time[1].split('-')[0]
        params.delivery_time_end   = this.formState.delivery_time[0] + ' ' + this.formState.delivery_time[1].split('-')[1]
      } else {
        params.pick_up_goods_time_start = this.formState.pickup_time[0] + ' ' + this.formState.pickup_time[1].split('-')[0]
        params.pick_up_goods_time_end   = this.formState.pickup_time[0] + ' ' + this.formState.pickup_time[1].split('-')[1]
      }



      let not_cake_list = this.goodsList.filter(el => el.goods_type!=3)
      params.goods_sku_data = JSON.stringify(not_cake_list.concat(this.cakeList))

      this.loading = true
      createOrder(params).then(res => {
      this.loading = false
        if (res.code === 0) {
          // 如果创建成功呢，提示并跳转到全部订单页
          if (res.data.submit_result === 'success') {
            this.$message.success('下单成功，请完成支付')
            this.$store.commit('tabsView/closeCurrentTab', this.$route)

            let orderId = res.data.success_data.order_no
            this.$router.push({path: '/shop-place-order', query: {orderId: orderId}})
          } else {
            this.$message.error(res.data.error_data.error_message)
            if (res.data.error_data.error_type === 3) {
              this.computedPrice()
            }
          }
        }
      })
    },

    handlerShowShopList () {
      if (this.formState.order_type === 2) {
        if (this.formState.consignee_lat && this.formState.consignee_lng) {
          this.isSelectShop = true
        } else {
          // 应该根据adcode算
          this.$message.warning('请先输入收货地址')
        }
      } else {
        this.isSelectShop = true
      }
    },

    handleShopChanged () {
      this.computedPrice()
    },
    
    async autoFigureShop(){
      // 重置
      this.formState.outlets_id = ""
      this.formState.outlets_name = ""
      this.formState.outlets_adcode = ""

      if(this.formState.delivery_outlets_id){
        this.formState.outlets_adcode = this.formState.consignee_adcode
        
        // 如果配送网点是门店、下单门店直接就用这个
        if(this.formState.delivery_outlets_type==1){
          this.formState.outlets_id = this.formState.delivery_outlets_id
          this.formState.outlets_name = this.formState.delivery_outlets_name
          // this.formState.outlets_adcode = this.formState.consignee_adcode
          this.handleShopChanged()
        }else{
        // 如果配送网点是配送站、算一个下单门店
          this.formState.outlets_name = "计算中..."

          let { code, data } = await getPickUpOutletsList({
            adcode: this.formState.consignee_adcode
          })
          this.formState.outlets_name = ""

          if(code == 0){
            let arr = data.list.map(item => {
              item.distance = getDistance(
                this.formState.consignee_lat, 
                this.formState.consignee_lng,
                item.outlets_lat_lng.split(' ')[1], 
                item.outlets_lat_lng.split(' ')[0]
              ).toFixed(2)
              return item
            })
            arr.sort((a,b)=>{return a.distance-b.distance})
            const shop = arr && arr[0]
            if(shop){
              this.formState.outlets_id = shop.outlets_id
              this.formState.outlets_name = shop.outlets_name
              // this.formState.outlets_adcode = this.formState.consignee_adcode

              this.handleShopChanged()
            }
          }
        } 
      }
    },
    handlerAddGoods () {
      if (this.formState.outlets_id) {
        this.isSelectGoods = true
      } else {
        // 自提：没选门店 先选择门店
        if(this.formState.order_type == 1){
          this.isSelectShop = true
        }
        if(this.formState.order_type == 2){
          this.$message.info('请先输入收货地址 计算配送网点')
        } 
      }
    },
    // 添加商品
    addGoodsOk(rows) {
      if(rows.some(el=>el.goods_type==4)){
        this.$message.warning('不能选择好茶')
        return
      }
      this.goodsList = rows.map(el => {
        if (!el.number) {
          el.number = 1
        }
        // 如果添加的有蛋糕，则放入蛋糕列表一份
        if (el.good_type === 3) {
          el.diners_number = 0
          el.birthday_candle = null
          el.birthday_card = ''
        }
        if(el.stock < 0){
          el.status = "无货"
        }else if(el.stock < el.number){
          el.status = "库存不足"
        }
        return el
      })
      this.updateCakeList()
      this.computedPrice()
      this.$forceUpdate()
    },

    changeGoodNumber(){
      this.updateCakeList()
      this.computedPrice()
    },

    // 删除
    handlerDeleteSku(index) {
      this.goodsList.splice(index, 1)
      this.updateCakeList()
      this.computedPrice()
    },

    updateCakeList(){
      let cakes = []
      this.goodsList.map(el => {
        if(el.goods_type == 3){
          let arr = this.cakeList.filter(ck => ck.sku_id == el.sku_id)
          for(let i = 0; i < el.number; i++){
            let cake = arr[i] || {}
            let item = Object.assign({}, el)
            Object.assign(item, {
              number: 1,
              diners_number: cake.diners_number,
              birthday_candle: cake.birthday_candle,
              birthday_card: cake.birthday_card,
            })
            cakes.push(item)
          }
        }
      })
      this.cakeList = cakes
    },

    // 计算订单价格
    computedPrice() {
      if (!this.goodsList.length) {
        this.formState.goods_amount = 0
      }
      
      if(this.formState.order_type == 1){
        this.formState.total = this.formState.goods_amount
      }else{
        this.formState.total = this.formState.goods_amount + this.formState.freight_amount
      }
      
      // 参数检测：商品
      if (!this.goodsList.length) return

      // 参数检测：网点
      if(!this.formState.outlets_id) return
      if(this.formState.order_type == 2){
        if(!this.formState.delivery_outlets_id) return
      }

      // 添加完商品后，进行订单的价格计算
      const params = {
        outlets_id: this.formState.outlets_id,
        goods_sku_data: JSON.stringify(this.goodsList.map(el => {
          return {
            sku_id: el.sku_id,
            number: el.number || 1
          }
        }))
      }
      getOrderPrice(params).then(res => {
        if(res.code == 0){
          let okHaxe, arr
          if(res.data.submit_result === 'error') {
            okHaxe = {}
            arr = res.data.error_data.goods_sku_list || []
            arr.map(el=>{
              okHaxe[el.sku_id] = el
            })
            this.goodsList.map(el=>{
              if(okHaxe[el.sku_id]){
                el.status = ""
                el.stock = okHaxe[el.sku_id].stock
                if(el.stock < 0){
                  el.status = "无货"
                }else if(el.stock < el.number){
                  el.status = "库存不足"
                }
              }else{
                el.status = "无货"
              }
              return el
            })
            this.$message.error(res.data.error_data.error_message)
            // return
          } else if(res.data.submit_result === 'success') {
            okHaxe = {}
            arr = res.data.success_data.goods_sku_list || []
            arr.map(el=>{
              okHaxe[el.sku_id] = el
            })
            this.goodsList.map(el=>{
              if(okHaxe[el.sku_id]){
                el.stock = okHaxe[el.sku_id].stock
                el.price = okHaxe[el.sku_id].price
                el.status = ""
              }
              return el
            })
            if(this.formState.order_type == 1){
              this.formState.goods_amount = res.data.success_data.goods_amount
              this.formState.total = parseFloat(res.data.success_data.total).toFixed(2)
            }else{
              this.formState.goods_amount = res.data.success_data.goods_amount
              this.formState.total = (
                  parseFloat(res.data.success_data.total) +
                  parseFloat(this.formState.freight_amount)
              ).toFixed(2)
            }
          }
        }
      })
    },

    handlerCopyUser(){
      if(this.formState.order_type == 1){
        this.formState.place_order_name = this.formState.recipient_name
        this.formState.place_order_phone = this.formState.recipient_phone
      }
      if(this.formState.order_type == 2){
        this.formState.place_order_name = this.formState.consignee_name
        this.formState.place_order_phone = this.formState.consignee_phone
      }
      this.$forceUpdate()
    },

    // 地址输入
    handleSearchAddress(value) {
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },

    async handleChangeAddress(value) {
      const item = this.addressList.find((item) => item.id === value)
      this.formState.consignee_address = item.address + item.title
      this.formState.consignee_lat = item.location.lat
      this.formState.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)
      if (adcodesRes && adcodesRes.status == 0) {
        if(adcodesRes.result.address_reference && adcodesRes.result.address_reference.town){
          this.formState.consignee_adcode = adcodesRes.result.address_reference.town.id + '000'
        }else{
          this.formState.consignee_adcode = adcodesRes.result.ad_info.adcode
        }

        // 非本城市
        if(this.shopCityAdcode){
          let v = checkAdcodes(this.formState.consignee_adcode, this.shopCityAdcode)
          if(v != 1){
            this.formState.delivery_outlets_name = ""
            this.formState.delivery_outlets_id = 0
            this.formState.delivery_region_id = 0
            this.$message.warning('非本城市地址')
            return;
          }
        }
        
        // 计算配送点
        let area = await searchShopDeliveryArea({
          lat: this.formState.consignee_lat,
          lng: this.formState.consignee_lng
        }, this.formState.consignee_adcode)
        if(area){
          this.formState.delivery_outlets_id = area.outlets_id
          this.formState.delivery_outlets_name = area.outlets_name
          this.formState.delivery_outlets_type = area.outlets_type
          this.formState.delivery_region_id = area.delivery_region_id
          this.formState.freight_amount = Math.round(Number(area.freight) * 100) / 100
          // this.computedPrice()
        }else{
          this.formState.delivery_outlets_id = 0
          this.formState.delivery_outlets_name = ''
          this.formState.delivery_outlets_type = ''
          this.formState.delivery_region_id = '';
          this.$message.warning("超区，不在配送范围内");
        }
        this.autoFigureShop()
      }
    },

    onChangeOrderType(){
      this.computedPrice()
    },

    validateForm() {
      if (this.formState.place_order_phone && !validatePhone(this.formState.place_order_phone)) {
        this.$message.warning('下单人手机号格式有误')
        return false
      }
      // 如果是自提
      if (this.formState.order_type === 1) {
        if (!this.formState.recipient_name) {
          this.$message.warning('请输入取货人姓名')
          return false
        }
        if (!this.formState.recipient_phone) {
          this.$message.warning('请输入取货人手机号')
          return false
        } else {
          if (!validatePhone(this.formState.recipient_phone)) {
            this.$message.warning('取货人手机号格式有误')
            return false
          }
        }
        if (!this.formState.pickup_time || !this.formState.pickup_time.length) {
          this.$message.warning('请选择取货时间')
          return false
        }
      }
      if (this.formState.order_type === 2) {
        if (!this.formState.consignee_name) {
          this.$message.warning('请输入收货人姓名')
          return false
        }
        if (!this.formState.consignee_phone) {
          this.$message.warning('请输入收货人手机号')
          return false
        } else {
          if (!validatePhone(this.formState.consignee_phone)) {
            this.$message.warning('收货人手机号格式有误')
            return false
          }
        }
        if (!this.formState.delivery_time || !this.formState.delivery_time.length) {
          this.$message.warning('请选择配送时间')
          return false
        }
        if (!this.formState.consignee_lat) {
          this.$message.warning('请输入收货地址')
          return false
        }
      }

      if (this.goodsList.length === 0) {
        this.$message.warning('请至少选择一个商品')
        return false
      }
      if (!this.formState.outlets_id) {
        this.$message.warning('请选择负责网点')
        return false
      }

      return true
    },

    handlerCancel() {
      this.$store.commit('tabsView/closeCurrentTab', this.$route)
      // 如果关闭的是当前页
      const tabsList = this.$store.getters['tabsView/tabsList']
      const currentRoute = tabsList[Math.max(0, tabsList.length - 1)]
      this.$router.push(currentRoute)
    }
  }
}
</script>

<style lang="less">
.input-line {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .require-i {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
}

.input-line-title {
  width: 100px;
  text-align: right;
}

.addr-region{
  margin-left: 10px;
  color: #ccc;
}

.thingtohide {
  animation: cssAnimation 0s ease-in 2s forwards;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
</style>