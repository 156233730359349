import { formatGoodsType, candleOptions } from '@/utils/type'

export const rowKey = "id";


export const detail_rowKey = "goods_number";

export const detail_columns = [
  {
    title: "产品属性",
    dataIndex: "goods_type",
    align: "center",
    width: 90,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
    width: "40%",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
    width: "40%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: 80,
  },
  {
    title: "商品总价",
    dataIndex: "goods_price",
    align: "center",
    width: 80,
  },
  {
    title: "支付金额",
    dataIndex: "order_price",
    align: "center",
    width: 80,
  },
  {
    title: "商品状态",
    dataIndex: "status",
    align: "center",
    width: 100,
    slots: {
      customRender: "status",
    },
  },
];

export const detail_columns_drink = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
    width: "40%",
  },
  // {
  //   title: "杯型",
  //   dataIndex: "specification_name",
  //   align: "center",
  //   width: "30%",
  // },
  {
    title: "属性",
    dataIndex: "show_desc",
    align: "center",
    width: "60%",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    width: 80,
  },
  {
    title: "商品单价",
    dataIndex: "unit_price",
    align: "center",
    width: 80,
    slots: { customRender: "unit_price" },
    slotsType: "all",
    slotsFunc: (row) => { return (Number(row.goods_price) / row.number).toFixed(2) },
  },
  {
    title: "商品总价",
    dataIndex: "goods_price",
    align: "center",
    width: 80,
  },
  {
    title: "折后总价",
    dataIndex: "order_price",
    align: "center",
    width: 80,
  },
  {
    title: "保温袋",
    dataIndex: "is_pack",
    align: "center",
    width: 80,
    slots: { customRender: "is_pack" },
    slotsType: "format",
    slotsFunc: (val) => {
      return val==2 ? "需要" : "——"
    },
  },
  {
    title: "是否退费",
    dataIndex: "is_refund",
    align: "center",
    width: 80,
    slots: { customRender: "is_refund" },
    // slotsType: "format",
    // slotsFunc: (val) => formatRefund(val),
  },
];


/********************  新增页面列表   *********************************************** */
export const add_columns = [// 门店列表
  {
    title: "SKU码",
    dataIndex: "sku_id",
    align: "center",
  },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "产品属性",
    dataIndex: "goods_type",
    align: "center",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "口味",
    dataIndex: "flavor_name",
    align: "center",
  },
  {
    title: "尺寸",
    dataIndex: "specification_name",
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "number",
    align: "center",
    slots: { customRender: "number" },
  },
  {
    title: "商品价格",
    dataIndex: "price",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 140,
    slots: { customRender: "operation" },
  }]


export {
  candleOptions
}

export default {
  detail_rowKey,
  detail_columns,
  detail_columns_drink,
  rowKey,
  candleOptions,

  add_columns
};
