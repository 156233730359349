import { getDeliveryOutletsList } from "@/api/outlets"
import { IsPtInPoly } from "@/utils/tMap.js"


// 门店下单： 地址 --> 能配送的网点（含配送范围）
export async function searchShopDeliveryArea(poi, adcode) {
	return new Promise((resolve, reject) => {
		getDeliveryOutletsList({
			adcode: adcode
		}).then((res) => {
			if(res.code == 0){
				let area = _getDeliveryArea(res.data.list, {
					lat: poi.lat,
					lng: poi.lng
				})
				if(!area){
					console.log("没有配送站能送到你家")
				}
				resolve(area);
			}else{
				reject(res)
			}
		})
	})
}

// 多网点（含配送范围） --> 能配送的网点（含配送范围）
function _getDeliveryArea(outletsList, poi) {
	let list = outletsList || [];
	let areaList = [];
	for (let i = 0; i < list.length; i++) {
		for (let j = 0; j < list[i].delivery_region_list.length; j++) {
			let area = list[i].delivery_region_list[j];
			area.outlets_id = list[i].outlets_id
			area.outlets_name = list[i].outlets_name
			// area.delivery_hours       = list[i].delivery_hours
			// area.delivery_hours_start = list[i].delivery_hours.split('-')[0]
			// area.delivery_hours_end   = list[i].delivery_hours.split('-')[1]
			areaList.push(area)
		}
	}
	areaList = areaList.sort((a, b) => a.freight - b.freight);
	for (let i = 0; i < areaList.length; i++) {
		let region = areaList[i].region;
		let tmp = region.split(",");
    let pointList = tmp.map(el => {
      let t = el.split(' ');
      return {
        latitude: Number(t[1]),
        longitude: Number(t[0])
      }
    })
		if (IsPtInPoly(poi.lat, poi.lng, pointList)) {
      return areaList[i]
    }
	}
	return null;
}
